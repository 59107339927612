















































































import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import InputLabel from '@/mixins/inputLabel.js'
import FilterBuilder, { EComponentTypes } from '@/components/InterfaceEditor/components/utils'

export default {
  name: 'a-card-autocomplete',
  inject: {
    forceUpdateSettingsPanel: { default: () => () => {} }
  },
  mixins: [mixin, VisibleMixin, InputLabel],
  props: {
    value: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    label: {
      type: String,
      description: 'Название'
    },
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    tooltip: {
      type: String,
      description: 'Подсказка'
    },

    placeholder: {
      type: String,
      description: 'Плейсхолдер'
    },
    size: {
      type: String,
      description: 'Размер',
      editor: 'Size'
    },
    align: {
      type: String,
      description: 'Выравнивание'
    },
    margin: {
      type: String,
      description: 'Отступы',
      default: '5px 10px'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    block: {
      type: Boolean,
      description: 'Во всю строку',
      default: true
    },
    wrapper: {
      type: Boolean,
      description: 'Блок'
    },
    config: {
      type: Object,
      editor: 'CardAutocomplete',
      description: 'Настройки',
      default () {
        return {
          service_type: 'da_data',
          data_type: 'party',
          object_id: null,
          label_field_id: null,
          search_fields: [],
          fields: []
        }
      }
    },
    filters: {
      type: Array,
      editor: 'Filters',
      default () {
        return []
      },
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  data () {
    return {
      popoverWidth: 400,
      suggestions: [],
      visible: false,
      query: null,
      suggest: null,
      loading: false,
      isAutoFill: false
    }
  },
  computed: {
    isDaData () {
      return this.config.service_type === 'da_data'
    },

    isAccent () {
      return this.config.service_type === 'accent'
    },

    hasSuggested () {
      return this.suggestions.length > 0
    },

    isParty () {
      return this.config.data_type === 'party'
    },

    isBank () {
      return this.config.data_type === 'bank'
    },

    computedClasses () {
      if (this.CSSClasses) {
        return `card-autocomplete ${this.CSSClasses}`
      }

      return 'card-autocomplete'
    },

    styles () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    },

    hasChanged () {
      if (this.suggest !== null) {
        return this.suggest.value !== this.query
      }

      return true
    },

    isEmptyQuery () {
      return this.query === null || this.query === ''
    },

    attrLabel () {
      return this.config.label_field_id ? `attr_${this.config.label_field_id}_` : 'id'
    }
  },
  watch: {
    // value (recordId) {
    //   if (recordId) {
    //     this.$http
    //       .get(`${this.$config.api}/registryservice/registry/${this.config.object_id}/card/${recordId}`)
    //       .then((response) => {
    //         this.isAutoFill = true
    //         this.suggest = response.data
    //         this.selected(response.data)
    //         this.fillInputField()
    //       })
    //   }
    // },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  methods: {
    input (value) {
      this.query = value
      this.visible = false
    },

    search () {
      this.popoverWidth = this.$refs.input.$el.getBoundingClientRect().width

      if (this.isEmptyQuery) {
        if (this.isAccent) {
          this.loading = true

          let query = null
          const dataFilters = this.dataFilters()
          const filters = dataFilters ? dataFilters.where.and : []

          if (filters.length) {
            query = {
              // fields: this.config.label_field_id ? ['id', this.attrLabel] : ['id'],
              where: {
                and: filters
              },
              limit: 30
            }
          } else {
            query = {
              limit: 30
            }
          }

          this.$http
            .post(`${this.$config.api}/registryservice/registry/${this.config.object_id}`, query, { hideNotification: true })
            .then((response) => {
              this.suggestions = response.data.data
              this.loading = false
              this.visible = true
            })
        }

        return
      }
      this.hints()
    },

    show () {
      if (this.suggestions.length > 0) {
        this.visible = true
      }
    },

    hints () {
      this.loading = true

      if (this.isDaData) {
        this.$daData
          .post(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${this.config.data_type}`, { query: this.query }, { hideNotification: true })
          .then((response) => {
            this.suggestions = response.data.suggestions
            this.loading = false
            this.visible = true
          })
      } else if (this.isAccent) {
        const query = {
          where: {
            and: this.dataFilters()
          },
          limit: 30
        }

        if (this.config.search_fields.length > 0) {
          const expressions = []

          this.config.search_fields.forEach((fieldId) => {
            expressions.push({
              like: {
                [`attr_${fieldId}_`]: `%${this.query}%`
              }
            })
          })

          query.where.and.push({
            or: expressions
          })
        }

        this.$http
          .post(`${this.$config.api}/registryservice/registry/${this.config.object_id}`, query, { hideNotification: true })
          .then((response) => {
            this.suggestions = response.data.data
            this.loading = false
            this.visible = true
          })
      }
    },

    selected (suggest) {
      if (this.isEmptyQuery) {
        this.query = suggest[this.attrLabel]

        // if (!this.isAutoFill) {
        //   this.hints()
        //   this.isAutoFill = true
        // }

        this.suggest = suggest

        this.fillInputField()
        this.loading = false

        return
      }

      if (this.hasChanged) {
        this.loading = true
        if (this.isDaData) {
          this.query = suggest.value

          this.$daData
            .post(`https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/${this.config.data_type}`, { count: 1, query: suggest.data.hid }, { hideNotification: true })
            .then((response) => {
              if (response.data.suggestions.length > 0) {
                this.suggest = response.data.suggestions[0]
                this.fillInputField()
                this.loading = false
              }
            })
        } else if (this.isAccent) {
          this.query = suggest[this.attrLabel]

          this.suggest = suggest

          this.fillInputField()
          this.loading = false
        }
      }

      this.visible = false
    },

    fillInputField () {
      if (this.suggest) {
        if (this.isDaData) {
          this.config.fields.forEach((field) => {
            let value = null
            if (['emails', 'phones'].indexOf(field.property) !== -1) {
              let values = []
              if (this.suggest.data[field.property]) {
                this.suggest.data[field.property].forEach(item => {
                  values.push(item.value)
                })
              }
              if (values.length > 0) {
                value = values.join('; ')
              }
            } else {
              let parts = field.property.split('.')

              value = this.suggest.data

              for (let i = 0; i < parts.length; i++) {
                value = value[parts[i]] || null
              }

              if (['state.actuality_date', 'state.registration_date', 'state.liquidation_date'].includes(field.property)) {
                value = value > 0
                  ? this.$moment.unix(Math.floor(value / 1000)).format('YYYY-MM-DD')
                  : null
              } else if (field.property === 'state.status') {
                const statuses = {
                  ACTIVE: 'Действующая',
                  LIQUIDATING: 'Ликвидируется',
                  LIQUIDATED: 'Ликвидирована',
                  BANKRUPT: 'Банкротство',
                  REORGANIZING: 'В процессе присоединения к другому юрлицу, с последующей ликвидацией'
                }

                value = value ? statuses[value] || null : value || null
              }
            }

            this.getModel()[field.attribute] = value
          })
        } else if (this.isAccent) {
          this.config.fields.forEach((field) => {
            try {
              // isXref был добавлен позже, по этому проверим, есть ли это свойство
              // Если нет, то по умолчанию равно false
              // Если есть, то берём значение из isXref - это может быть true или false
              field.isXref = typeof field.isXref === 'undefined'
                ? false
                : field.isXref

              const externalAttribute = field.isXref
                ? `attr_${field.property}_id`
                : `attr_${field.property}_`

              // Если ссылочное поле и тип данных string, значит это мн. ссылка с форматом "{1, 2, 3}" (Формат массивов PgSQL)
              if (field.isXref && typeof this.suggest[externalAttribute] === 'string') {
                this.suggest[externalAttribute] = JSON.parse(
                  this.suggest[externalAttribute]
                    .replace(/\{/g, '[')
                    .replace(/\}/g, ']')
                )
              }

              this.getModel()[field.attribute] = this.suggest[externalAttribute]
            } catch (e) {
              // Есть вероятность, что JSON.parse выбросит исключение
              console.error({
                currentAttribute: field.attribute,
                externalAttribute: field.property,
                error: e
              })
            }
          })
        }
      }
    },

    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.cardAutocomplete
      )

      return builder.buildAsApiQl()

      // const filters = []
      //
      // if (this.filters.length > 0) {
      //   this.filters.forEach((item) => {
      //     let type = item.isXref ? 'equals_any' : 'eq'
      //     let object = {}
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias) {
      //         object[type] = {}
      //         object[type][item.alias] = this.getModel()[item.attribute]
      //         filters.push(object)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       object[type] = {}
      //       object[type][item.alias] = item.attribute
      //       filters.push(object)
      //     } else if (item.type === 'current_user' && item.alias) {
      //       object[type] = {}
      //       object[type][item.alias] = this.$store.getters['Authorization/userId']
      //       filters.push(object)
      //     }
      //   })
      // }
      //
      // return filters
    }
  }
}
